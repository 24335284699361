.row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Выравнивание по центру на всю высоту экрана */
  }
  
  .card {
    width: 300px; /* Ширина блока .card */
    padding: 20px 0; /* Отступы вокруг блока .card */
    border: 1px solid #ccc; /* Рамка вокруг блока .card */
    border-radius: 5px; /* Скругление углов */
    background-color: #fff; /* Фон блока .card */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Тень блока .card */
    
  }
  
  .input-field {
    margin-bottom: 20px; /* Отступ между полями ввода */
  }
  
  .card h2 {
    font-size: 24px; /* Изменяем размер текста на 24 пикселя */
  }

  .btn-reset {
    width: 100%;
    margin-top: 20px;
    background-color: #b4aae3; /* Изменяем цвет кнопки */
  }
  
  .btn-reset:hover {
    background-color: #82d9e9; /* Цвет при наведении */
  }