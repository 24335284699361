.elem_0 {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 0 15px rgba(0, 123, 255, 0.4);
  animation: pulse 2s infinite ease-in-out;
  transition: all 0.3s ease;
}

.elem_0:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 0 25px rgba(0, 123, 255, 0.6);
}

@keyframes pulse {
  0% {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 0 15px rgba(0, 123, 255, 0.4);
  }
  50% {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 0 35px rgba(0, 123, 255, 0.7);
  }
  100% {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 0 15px rgba(0, 123, 255, 0.4);
  }
}
