.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.card-container {
  width: 100%;
  max-width: 400px;  /* Сохраняем фиксированную ширину */
  padding: 40px;     /* Увеличиваем отступы сверху и снизу для увеличения высоты */
  text-align: center;
}

.card {
  background-color: #e9dfec;
  padding: 30px;     /* Увеличиваем внутренние отступы, чтобы дать больше места */
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 2rem;
  margin-bottom: 20px; /* Увеличиваем отступ снизу, чтобы создать больше места между заголовком и полями */
  margin-top: -15px;   /* Немного поднимем заголовок */
}

.input-field {
  margin-bottom: 5px; /* Увеличиваем отступы между полями формы */
}

.btn-register {
  width: 100%;
  margin-top: 15px;    /* Увеличиваем отступ сверху для кнопки */
  background-color: #4a90e2; /* Цвет кнопки, как в логине */
  border: none;
  padding: 12px;
  color: white;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-register:hover {
  background-color: #45a049; /* Цвет кнопки при наведении */
}

select {
  display: block;
  width: 100%;
  height: 40px;  /* Увеличиваем высоту выпадающего списка */
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

select:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.floating-label input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: transparent;
}

.floating-label label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  transition: all 0.2s ease;
  color: #aaa;
  pointer-events: none;
}

.floating-label input:focus + label,
.floating-label input:not(:placeholder-shown) + label {
  top: -10px;
  font-size: 0.9rem;
  color: #a8a8a8;
}
