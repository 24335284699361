*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* overflow: hidden; */
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important; 
}
::-webkit-scrollbar {
    width: 0;
}
body{
    margin: 0;
    padding: 0;
    background-color: #EAEAEA;
    min-height: 100vh;
    background-color: rgb(9,114,110);
    /* font-family: 'Jost', sans-serif; */


    font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html, body {
    height: 100%;
}
h1, h2, h3, h4, p{
    margin: 0;
    padding: 0;
    /* line-height: 1; */
}
button{
    text-decoration: none;
    border: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important; 
}
.container{
    width: 1300px;
    margin: 0 auto;

}



/* header */

.headd{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 18px;
    height: 84px;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    
}

.head_logo{
    display: flex;
    align-items: center;
    gap: 12px;
}

.head_logo img{
    width: 46px;
    height: 55px;
}
.head_logo h1{
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    color: #fff;
}


.head_navigat{
    display: flex;
    align-items: center;
    gap: 15px;
}
.ewt{
    display: flex;
    align-items: center;
    gap: 12px;
}
.head_navigat img .email{
    width: 26px;
    height: 21px;
}
.head_navigat img .wallet{
    width: 21px;
    height: 21px;
}
.head_navigat img .sun{
    width: 24px;
    height: 24px;
}

.head_navigat_button{
    display: flex;
    gap: 10px;
}
.head_navigat_button button{
    font-weight: 400;
    font-size: 22px;
    text-align: center;
    color: #fff;
    background-color: rgba(255, 255, 255, 0);
    border: 1.50px solid #fff;
    border-radius: 3px;
    padding: 2px 6px;
}


/* main */

.main_obj{
    background-image: url("../assets/images/main_obj.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    padding-top: 35vh;
}


.mission{
    
}
.mission h2{
    font-weight: 500;
    font-size: 40px;
    line-height: 62%;
    color: #fff;
    margin-bottom: 22px;
}
.mission p{
    font-weight: 400;
    font-size: 26px;
    line-height: 96%;
    color: #fff;
    width: 500px;
}
.mission p:not(:last-child){
    margin-bottom: 4px;
}



.event_map_main{
    height: 560px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(9,123,118);
    position: relative;
}
.event_map{

}
.event_map h2{
    font-weight: 500;
    font-size: 32px;
    line-height: 78%;
    color: #d6f4e8;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 40px;
}
.select_map{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
}
.select_map img{
    width: 702px;
    height: 295px;
}
.select{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.select select{
    font-weight: 400;
    font-size: 17px;
    line-height: 100%;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 3px;
    width: 144px;
    height: 35px;
    background-color: rgba(0, 0, 0, 0);
}
.select input{
    border: 2px solid #fff;
    border-radius: 3px;
    width: 144px;
    height: 35px;
    font-weight: 400;
    font-size: 20px;
    line-height: 125%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);;
}
.select button{
    font-weight: 500;
    font-size: 20px;
    line-height: 125%;
    text-align: center;
    color: #111827;
    border-radius: 10px;
    width: 120px;
    height: 37px;
    background: #d6f4e8;
}




.events{
    height: 560px;
    background-color: rgb(9,114,110);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.events h2{
    font-weight: 500;
    font-size: 32px;
    line-height: 78%;
    color: #d6f4e8;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 40px;
}
.events_elem{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.events_elem .elem_info{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.events_elem .elem h3{
    font-weight: 500;
    font-size: 20px;
    line-height: 125%;
    color: #111827;
    margin-top: 10px;
    text-align: center;
}
.events_elem .elem{
    width: 230px;
    height: 290px;
    box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.4);
    background: #d6f4e8;
    border-radius: 3px;
}

.events_elem .elem .mesto, .events_elem .elem .date{
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 156%;
    color: #111827;
    margin-left: 10px;
}
.events_elem img{
    width: 160px;
    height: 160px;
}
.events_elem .event_img{
    width: 230px;
    height: 162px;
}
.elem_0{
    width: 230px;
    height: 290px;
    background: url("../assets/images/elem_0.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 18px;
    box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.4);
}
.elem_0 img{
    width: 50px;
    height: 50px;
}
.elem_0 p{
    font-weight: 400;
    font-size: 24px;
    line-height: 104%;
    color: #d6f4e8;
    width: 80%;
    text-align: center;
}
.elem_0 span{
    font-weight: 400;
    font-size: 36px;
    line-height: 69%;
    color: #d6f4e8;
}


.about_us_container{
    height: 560px;
    background-color: rgb(9,123,118);
    display: flex;
    align-items: center;
    justify-content: center;
}
.about_us{

}
.about_us .img_info{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
}
.about_us .img_info .img{
    width: 440px;
    height: 340px;
}
.info_all{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}
.info_all .info{
    padding: 20px;
    text-align: start;
    width: 340px;
}
.info_all .info:nth-child(1){
    padding-top: 0px;
}
.info_all .info:nth-child(2){
    padding-top: 0px;
}
.info_all .info h3{
    font-weight: 500;
    font-size: 26px;
    line-height: 96%;
    color: #d6f4e8;
    margin-bottom: 10px;
}
.info_all .info p{
    font-weight: 500;
    line-height: 96%;
    color: #d6f4e8;
    font-size: 16px;
    line-height: 94%;
}








/* footer */
footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(0,52,68);
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
}
.footer_info{
    width: 100%;
    padding: 20px 40px;
    border-top: 1px solid rgb(9,114,110);
    display: flex;
    flex-direction: column;
    gap: 14px;
}
footer .footer_info .gr1, .footer_info .gr2{
    display: flex;
    justify-content: space-between;
}
footer h2{
    font-weight: 400;
    font-size: 24px;
    color: #fff;
    width: 20%;
    text-align: center;
    margin: 10px 0 30px;
}
.footer_info p{
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #fff;
    width: 300px;
}
.footer_info input{
    all: unset;
    width: 300px !important;
}
.gr1 p:first-child{
    text-align: start;
}
.gr1 p:last-child{
    text-align: end;
}
.gr2 input:first-child{
    text-align: start;
}
.gr2 p:last-child{
    text-align: end;
}