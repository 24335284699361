.chat-container {
    display: flex;
    height: 90vh;
    margin: 20px;
    background-color: #1a1a1a;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }
  
.sidebar {
  width: 30%;
  background-color: #2d2d2d;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.contact-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.contact-list li {
  padding: 15px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  cursor: pointer;
  transition: all 0.2s ease;
  color: #ffffff;
}

.contact-list li:hover {
  background-color: #363636;
}

.chat-section {
  width: 70%;
  display: flex;
  flex-direction: column;
  background-color: #202020;
}

.chat-header {
  padding: 20px;
  background-color: #2d2d2d;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
}

.chat-window {
  padding: 20px;
  overflow-y: auto;
  flex-direction: column;
  background-color: #202020;
  display: flex;
  height: calc(90vh - 140px);
}


.message-bubble {
  margin: 8px 0;
  padding: 12px 16px;
  border-radius: 18px;
  max-width: 60%;
  word-wrap: break-word;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.message-right {
  background-color: #008080;
  color: white;
  align-self: flex-end;
  text-align: right;
  border-bottom-right-radius: 4px;
}

.message-right strong {
  color: #e0f2f1 !important;
}

.message-right small {
  color: rgba(255, 255, 255, 0.7) !important;
}

.message-left {
  background-color: #2d2d2d;
  color: #ffffff;
  align-self: flex-start;
  text-align: left;
  border-bottom-left-radius: 4px;
}

.message-left strong {
  color: #b2dfdb !important;
}

.message-left small {
  color: rgba(255, 255, 255, 0.5) !important;
}

.message-bubble.contact {
  background-color: #f8d7da;
  align-self: flex-start;
}

.message-bubble.user {
  background-color: #ffffff; /* Цвет для сообщений пользователя */
  text-align: right;
}

.input-field {
  display: flex;
  gap: 10px;
  margin-top: auto;
  padding: 20px;
  background-color: #3a3a3a;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.input-field-register {
  margin-bottom: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.message-bubble {
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
}

.message-bubble.user {
  background-color: #3f3f3f; /* Цвет для сообщений пользователя */
  text-align: right;
}

.message-bubble.contact {
  background-color: #f1f1f1; /* Цвет для сообщений других пользователей */
  text-align: left;
}

.message-header {
  font-weight: bold;
}

.message-content {
  margin: 5px 0;
}

.message-time {
  font-size: 0.8em;
  color: gray;
}

.message-bubble strong {
  color: #525252;
  font-weight: 500;
}

.message-bubble small {
  color: #546e7a;
  font-size: 0.75rem;
}

/* Стили для скроллбара */
.chat-window::-webkit-scrollbar {
  width: 6px;
}

.chat-window::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.chat-window::-webkit-scrollbar-thumb {
  background: #404040;
  border-radius: 3px;
}

.chat-window::-webkit-scrollbar-thumb:hover {
  background: #4a4a4a;
}

/* Стилизация бейджа для непрочитанных сообщений */
.MuiBadge-badge {
  background-color: #008080 !important;
  color: #ffffff;
}


